<!--
 * @Description: 路内停车管理系统 数据看板 路内运营总览 onroadOperationsOverview
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 14:43:46
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label="手机号"
                          label-width="90px">
              <el-input v-model="searchForm.phoneNum"
                        placeholder="手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="行政区"
                          label-width="90px">
              <el-select class="search-input"
                         clearable
                         filterable
                         v-model="searchForm.substationId"
                         placeholder="行政区">
                <el-option v-for="item in administrativeRegions"
                           :key="item.stationId"
                           :label="item.stationName"
                           :value="item.stationId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>

          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>路内运营总览</span>
        <el-button type="info"
                   class="tableTitleButton">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
          </template>
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClick(scope.row)"
                             type="text"
                             size="medium">查看</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             @click="handleClick(scope.row)"
                             size="medium">编辑</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //  这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'id',
          label: '订单号',
        },
        {
          prop: 'phone',
          label: '手机号',
        },
        {
          prop: 'num',
          label: '车牌号',
        },
        {
          prop: 'bowei',
          label: '泊位号',
        },
        {
          prop: 'luduan',
          label: '路段',
        },
        {
          prop: 'pay',
          label: '缴费金额（元）',
        },
        {
          prop: 'type',
          label: '付款方式',
        },
        {
          prop: 'bujiao',
          label: '缴费时间',
        },
        {
          prop: 'time',
          label: '生成时间',
        },
      ],
      tableList: {
        list: [],
      },
      searchForm: {},
      pageNum: 1,
      pageSize: 15,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    getList () {
      console.warn('获取内容')
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
//@import url(); 引入公共css类
// 最外层div
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}

.roadOperation {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .table-container {
    width: 100%;
    background: rgba(10, 33, 67, 0.7);
    box-sizing: border-box;
    padding: 1.5rem 1.25rem;
  }

  .tb-title {
    width: 100%;
    box-sizing: border-box;
    font-family: PingFangSC-Medium;
    font-size: 1rem;
    color: #f8fdff;
    margin-bottom: 0.625rem;
    text-align: left;
    position: relative;

    .create-bt {
      position: absolute;
      right: 1rem;
      top: 0rem;
    }
  }
}

.page {
  margin-top: 1rem;
  text-align: right;
  color: #ffffff;
}

.form-input {
  width: 188px;
}
</style>
